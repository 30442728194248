import { useUser } from "../lib/hooks";
import { useRouter } from "next/router";
import { Dropdown, Row, Spacer, Text } from "@nextui-org/react";

const Header = () => {
  const user = useUser();
  const router = useRouter();

  const goTo = ([key]) => {
    if (key === "logout") {
      key = "api/auth/logout";
    }
    router.push(`/${key}`);
  };

  return (
    <header>
      <nav>
        <Spacer y={0.5} />
        <Row justify="flex-end" align="center">
          {typeof user === "undefined" ? (
            <Text b style={{ marginRight: "1.2em", userSelect: "none" }}>
              ☰
            </Text>
          ) : (
            <>
              <Dropdown placement="left-bottom">
                <Dropdown.Trigger>
                  <Text
                    b
                    aria-label="Open application menu"
                    style={{ userSelect: "none" }}
                  >
                    ☰
                  </Text>
                </Dropdown.Trigger>
                {user ? (
                  // Slightly verbose, but a consequence of Dropdown's use of
                  // children
                  <Dropdown.Menu
                    variant="light"
                    aria-label="Actions"
                    selectionMode="single"
                    onSelectionChange={goTo}
                  >
                    <Dropdown.Item key="submissions">
                      My submissions
                    </Dropdown.Item>
                    <Dropdown.Item key="journals">My journals</Dropdown.Item>
                    <Dropdown.Item key="profile">My profile</Dropdown.Item>
                    <Dropdown.Item key="logout" withDivider>
                      Log out
                    </Dropdown.Item>{" "}
                  </Dropdown.Menu>
                ) : (
                  <Dropdown.Menu
                    variant="light"
                    aria-label="Actions"
                    selectionMode="single"
                    onSelectionChange={goTo}
                  >
                    <Dropdown.Item key="login">Log in</Dropdown.Item>
                    <Dropdown.Item key="signup">Sign up</Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
              <Spacer x={1} />
            </>
          )}
        </Row>
      </nav>
    </header>
  );
};

export default Header;
