import Head from "next/head";
import Header from "./header";
import { Container, Spacer } from "@nextui-org/react";

const Layout = (props) => (
  <>
    <Head>
      <title>Editorland | Submission management for everyone.</title>
    </Head>
    <Header />
    <Container xs>
      <Spacer />
      <main>
        <div className="container">{props.children}</div>
      </main>
    </Container>
  </>
);

export default Layout;
