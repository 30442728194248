import { Container, Card, Row, Text, Spacer } from "@nextui-org/react";

// type is one of neutral, primary, secondary, success, warning, error, gradient
// TODO actually, we've swapped in success -> neutral; gradient currently looks bad
export default function Banner({ type, children }) {
  if (type === "success") {
    type = "primary";
  }
  if (
    !["primary", "secondary", "warning", "error", "gradient"].includes(type)
  ) {
    throw new Error(`Unsupported variant ${type}.`);
  }
  return (
    <Container>
      <Card css={{ $$cardColor: `$colors$${type}` }}>
        <Card.Body>
          <Row justify="center" align="center">
            <Text h6 size={15} color="white" css={{ m: 0 }}>
              {children}
            </Text>
          </Row>
        </Card.Body>
      </Card>
      <Spacer y={1} />
    </Container>
  );
}
