import { useRouter } from "next/router";
import Banner from "../components/Banner.js";
import Layout from "../components/layout";
import { Spacer, Text } from "@nextui-org/react";
import Link from "next/link.js";

const Home = () => {
  const router = useRouter();
  const { loggedin } = router.query;

  return (
    <Layout>
      {loggedin !== undefined && (
        <Banner type="success">You are now logged in.</Banner>
      )}
      <Text h1>Editorland</Text>
      <Text b>Submission management for everyone.</Text>
      <Spacer y={0.5} />
      <Text>
        Editorland is a totally-free submission management tool that's provided
        as a service to literary, scientific, or other subject-matter journals.
      </Text>
      <Spacer y={0.5} />
      <Text>
        This version of Editorland is in an open beta.{" "}
        <Link href="/about">Learn more →</Link>
      </Text>
    </Layout>
  );
};

export default Home;
